<template>
  <div class="gateway homepage">
    <!-- header 头部 -->
    <gateway-header />
    <!-- header 头部 end -->

    <div class="gateway-banner">
      <video
        src="../../assets/video/video-banner.mp4"
        autoplay
        muted
        loop
      ></video>
    </div>
    <div class="fishery-park gateway-module">
      <div class="module-title">数字渔业园区</div>
      <div class="module-content">
        <div class="column-box">
          <div class="ls-item" v-for="(ele, idx) in columnls" :key="idx">
            <img :src="ele.img" alt="" />
            <span v-show="ele.num">{{ ele.num }}</span>
            <i v-show="ele.desc">{{ ele.desc }}</i>
          </div>
        </div>
        <div class="row-box">
          <div class="ls-item" v-for="(ele, idx) in rowls" :key="idx">
            <img :src="ele.img" alt="" />
            <span>{{ ele.num }}</span>
            <i>{{ ele.desc }}</i>
          </div>
        </div>
      </div>
    </div>

    <div class="ecological-chain gateway-module">
      <div class="module-content">
        <div class="content-left">
          <div class="title">湛渔生态链</div>
          <ul>
            <li
              v-for="(ele, idx) in chainls"
              :key="idx"
              :class="{ active: idx === chainAct }"
              @click="chainAct = idx"
              @mouseenter="chainAct = idx"
            >
              {{ ele.txt }}
            </li>
          </ul>
        </div>
        <div class="content-right">
          <ul>
            <li>10 +家种苗养殖企业</li>
            <li>400+家繁育场</li>
            <li>20+种养殖品种</li>
            <li>2万+尾鱼苗</li>
            <li>45+亿元产值</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="settled-enterprise gateway-module">
      <div class="module-title">产业园入驻企业</div>
      <div class="module-content">
        <div class="sub-nav-box">
          <div
            class="ls-item"
            v-for="(ele, idx) in chainls"
            :key="idx"
            :class="{ active: idx === enterpriseAct }"
            @click="enterpriseAct = idx"
            @mouseenter="enterpriseAct = idx"
          >
            {{ ele.txt }}
          </div>
        </div>
        <div class="company-ls">
          <div class="ls-item" v-for="ele in 10" :key="ele">
            <img src="../../assets/img/large-screen/76px/100.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="news-center gateway-module">
      <div class="module-title">新闻中心</div>
      <div class="module-content">
        <div class="news-nav-box">
          <div class="ls-item" v-for="ele in 3" :key="ele">
            <div class="img-box">
              <img
                src="../../assets/img/large-screen/bg-video-wrap.png"
                alt=""
              />
              <div class="new-detail">
                新闻详情
                <img
                  src="../../assets/img/gateway/homepage/img-turn-right.png"
                  alt=""
                />
              </div>
            </div>
            <div class="item-center">
              <h5>09</h5>
              <span>2021年9月</span>
              <i>时间——</i>
            </div>
            <div class="item-left">
              <div class="user-name">By恒心</div>
              <h5>湛江海洋生态保护不停歇</h5>
              <article>
                创业启动资金支持，助您轻松回家创业，创业启动资金支持，助您轻松回家创业
                创业启动资金支持，助您轻松回家创业。
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gateway-footer :style="'background: #ffffff; color: #1b1e2a;'" />
    <ToTopperComponent />
  </div>
</template>
<script>
import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'
export default {
  name: 'homepage',
  data() {
    return {
      columnls: [
        {
          num: '5,800',
          desc: '年总产值/亿元',
          img: require('../../assets/img/gateway/homepage/icon-fishery-park-01.png'),
        },
        {
          num: '5,800',
          desc: '年总产值/亿元',
          img: require('../../assets/img/gateway/homepage/icon-fishery-park-02.png'),
        },
        {
          img: require('../../assets/img/gateway/homepage/icon-fishery-park-03.png'),
        },
        {
          num: '5,800',
          desc: '年总产值/亿元',
          img: require('../../assets/img/gateway/homepage/icon-fishery-park-04.png'),
        },
        {
          num: '5,800',
          desc: '年总产值/亿元',
          img: require('../../assets/img/gateway/homepage/icon-fishery-park-05.png'),
        },
      ],
      rowls: [
        {
          num: '5,800',
          desc: '年总产值/亿元',
          img: require('../../assets/img/gateway/homepage/icon-fishery-park-06.png'),
        },
        {
          num: '5,800',
          desc: '年总产值/亿元',
          img: require('../../assets/img/gateway/homepage/icon-fishery-park-07.png'),
        },
        {
          num: '5,800',
          desc: '年总产值/亿元',
          img: require('../../assets/img/gateway/homepage/icon-fishery-park-08.png'),
        },
        {
          num: '5,800',
          desc: '年总产值/亿元',
          img: require('../../assets/img/gateway/homepage/icon-fishery-park-09.png'),
        },
      ],
      chainls: [
        { type: 1, txt: '苗种繁育' },
        { type: 1, txt: '饲料产销' },
        { type: 1, txt: '深海养殖' },
        { type: 1, txt: '加工仓储' },
        { type: 1, txt: '冷链物流' },
      ],
      chainAct: 0,
      enterpriseAct: 0,
    }
  },
  computed: {},
  methods: {},
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  components: {
    GatewayFooter,
    GatewayHeader,
    ToTopperComponent,
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './homepage.less';

</style>
